// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-templates-single-photo-gallery-single-photo-gallery-js": () => import("./../../../src/templates/single-photo-gallery/single-photo-gallery.js" /* webpackChunkName: "component---src-templates-single-photo-gallery-single-photo-gallery-js" */),
  "component---src-templates-single-post-single-post-js": () => import("./../../../src/templates/single-post/single-post.js" /* webpackChunkName: "component---src-templates-single-post-single-post-js" */),
  "component---src-templates-tag-page-tag-page-js": () => import("./../../../src/templates/tag-page/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-tag-page-js" */),
  "component---src-templates-tags-page-tags-page-js": () => import("./../../../src/templates/tags-page/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-tags-page-js" */)
}

